/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import PropTypes from "prop-types";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
import { capitalizeFirstLetter } from "util/formatDateAndTime";
// Billing page components
import Transaction from "layouts/billing/components/Transaction";
import { useMaterialUIController } from "context";
import { convertTo12HourFormat } from "util/formatDateAndTime";
function ReportCard({ report, filter }) {
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    return (
        <Card sx={{ height: "100%" }}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
                <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                    Report Card
                </MDTypography>
                <MDBox>
                    <MDBox display="flex" alignItems="flex-start">
                        <MDBox color={darkMode ? "white" : "black"} mr={0.5} lineHeight={0}>
                            <Icon color="inherit" fontSize="small">
                                date_range
                            </Icon>
                        </MDBox>
                        <MDBox display={'flex'} flexDirection={'column'}>
                            <MDTypography variant="button" color="text" fontWeight="regular" mr={2}>
                                {/* 23 - 30 March 2020 */}
                                {(filter?.startDate === "all" || filter?.endDate === "all") ? "Alltime" : `${filter?.startDate} - ${filter?.endDate} `}
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                    {
                        (filter?.startTime === "all" || filter?.endTime === "all") ? null : (
                            <MDBox mt={0.5} display="flex" alignItems="flex-start">
                                <MDBox color={darkMode ? "white" : "black"} mr={0.5} lineHeight={0}>
                                    <Icon color="inherit" fontSize="small">
                                        access_time
                                    </Icon>
                                </MDBox>
                                <MDBox display={'flex'} flexDirection={'column'}>
                                    <MDTypography variant="button" color="text" fontWeight="regular" mr={2}>
                                        {/* 11:30pm - 01:30pm*/}
                                        {(filter?.startTime === "all" || filter?.endTime === "all") ? null : ` ${convertTo12HourFormat(filter?.startTime)} - ${convertTo12HourFormat(filter?.endTime)} `}
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                        )
                    }

                </MDBox>
            </MDBox>
            <MDBox pt={1} pb={2} px={2}>
                <MDBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    sx={{ listStyle: "none" }}
                >
                    <Transaction
                        color="info"
                        icon="person"
                        name="Merchant"
                        value={filter?.activeMerchant?.toUpperCase()}
                    />
                    <Transaction
                        color="info"
                        icon="account_balance"
                        name="Gateway"
                        value={filter?.activeGateway?.toUpperCase()}
                    />
                </MDBox>
                <MDBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    sx={{ listStyle: "none" }}
                >
                    <Transaction
                        color={filter?.status === "success" ? "success" : filter?.status === "IN-PROCESS" ? "warning" : "error"}
                        icon={filter?.status === "success" ? "check_circle" : filter?.status === "IN-PROCESS" ? "warning" : "error"}
                        name="Status"
                        value={filter?.status === "IN-PROCESS" ? "PENDING" : filter?.status?.toUpperCase()}
                    />
                    <Transaction
                        color={filter?.status === "success" ? "success" : filter?.status === "IN-PROCESS" ? "warning" : "error"}
                        icon="currency_rupee"
                        name="Gross Amount"
                        value={`₹ ${report?.totalVolume?.toFixed(0)}`}
                    />
                    <Transaction
                        color={filter?.status === "success" ? "success" : filter?.status === "IN-PROCESS" ? "warning" : "error"}
                        icon="blur_on"
                        name="Transaction Count"
                        value={report?.totalCount}
                    />
                </MDBox>
            </MDBox>
        </Card >
    );
}

ReportCard.propTypes = {
    report: PropTypes.object,
    filter: PropTypes.object
}
export default ReportCard;
