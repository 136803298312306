/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
// import { useNavigate } from 'react-router-dom';
// Material Dashboard 2 React components

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from 'components/MDInput';
import Card from "@mui/material/Card";
import { MenuItem } from '@mui/material';
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useConfig } from "../../../config"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
// import { useUser } from "ZustandState/useUser";
import { useMaterialUIController } from 'context';
import { capitalizeFirstLetter } from 'util/formatDateAndTime';
import { useSandbox } from 'ZustandState/useSanbox';
// Data
function addMerchant() {
    const sandbox = useSandbox((state) => state.sandbox)
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    const adminEmail = localStorage.getItem('admin_email')
    const adminApiKey = localStorage.getItem('admin_apiKey')
    // const user = useUser((state) => state.user)
    const [openDialog, setOpenDialog] = useState(false);
    const [responseData, setResponseData] = useState(null);
    const { apiUrl, testUrl } = useConfig()
    const [gateways, setGateways] = useState([])
    const validationSchema = Yup.object({
        emailId: Yup.string().required('Email is required'),
        first_name: Yup.string().required('First Name is required'),
        last_name: Yup.string().required('Last Name is required'),
        business_name: Yup.string().required('Business Name is required'),
        business_type: Yup.string().required('Business Type is required'),
        gateway: Yup.string().required('gateway is required'),

    });

    const initialValues = {
        email_Id: adminEmail || '',
        apiKey: adminApiKey || '',
        emailId: '',
        first_name: '',
        last_name: '',
        business_name: '',
        business_type: '',
        gateway: ''
    };
    // with this we can get all the gateway info for dropdown 
    const getAllGateways = async () => {
        let body = {
            emailId: adminEmail || '',
            apiKey: adminApiKey || '',
        }
        try {
            const response = await fetch(`${apiUrl}/admin/getallgateways`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            setGateways(res?.responseData)
        } catch (err) {
            console.log("Error Fetching getAllGateways: ", err)
        }
    }
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            if (values.emailId === '' || values.apiKey === '') {
                toast.error("Invalid credential! Please Sign in again..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            const response = await fetch(`${apiUrl}/user/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }

            setSubmitting(false);

            const userBody = {
                emailId: res.responseData.email,
                password: res.responseData.password,
                apiKey: res.responseData.apiKey
            }
            setResponseData(userBody);
            setOpenDialog(true);
            toast.success(res.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });
        } catch (error) {
            // Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });

            // Reset submitting state
            setSubmitting(false);
        }
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleCopyToClipboard = () => {

        const dataToCopy = `Dashboard: payhub.link\n\nEmail id: ${responseData.emailId}\n\nPassword: ${responseData.password}\n\nApikey: ${responseData.apiKey}`;
        navigator.clipboard.writeText(dataToCopy);
        toast.success('Merchant data copied to clipboard', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
        });
    };
    useEffect(() => {
        getAllGateways()
    }, [sandbox])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Card>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        mx={2}
                        mt={5}
                        p={2}
                        mb={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                            Add Merchant
                        </MDTypography>
                    </MDBox>
                    <MDBox pt={4} pb={3} px={3}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <MDBox mb={2}>
                                        <Field type="text" label="First Name" as={MDInput} fullWidth name="first_name" />
                                        <MDTypography color="error" variant="caption" >
                                            <ErrorMessage name="first_name" component="div" />
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <Field type="text" label="Last Name" as={MDInput} fullWidth name="last_name" />
                                        <MDTypography color="error" variant="caption" >
                                            <ErrorMessage name="last_name" component="div" />
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <Field type="text" label="Email Id" as={MDInput} fullWidth name="emailId" />
                                        <MDTypography color="error" variant="caption" >
                                            <ErrorMessage name="emailId" component="div" />
                                        </MDTypography>
                                    </MDBox>

                                    <MDBox mb={2}>
                                        <Field type="text" label="Business Name" as={MDInput} fullWidth name="business_name" />
                                        <MDTypography color="error" variant="caption" >
                                            <ErrorMessage name="business_name" component="div" />
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <Field type="text" label="Business Type" as={MDInput} fullWidth name="business_type" />
                                        <MDTypography color="error" variant="caption" >
                                            <ErrorMessage name="business_type" component="div" />
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <Field
                                            type="text"
                                            select
                                            label="Select Gateway"
                                            size="large"
                                            as={MDInput}
                                            fullWidth
                                            name="gateway"
                                            InputProps={{
                                                classes: { root: darkMode ? "select-input-styles-darkMode" : "select-input-styles" },
                                            }}
                                        >
                                            <MenuItem value="">Select Gateway</MenuItem>
                                            {gateways && gateways.map((item, index) => (
                                                <MenuItem key={index} value={item.gatewayName}>
                                                    {capitalizeFirstLetter(item.gatewayName)}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                        <MDTypography color="error" variant="caption" >
                                            <ErrorMessage name="gateway" component="div" />
                                        </MDTypography>
                                    </MDBox>

                                    <MDBox mt={4} mb={1}>
                                        <Grid container justifyContent="center">
                                            <Grid item>
                                                <MDButton
                                                    variant="gradient"
                                                    color="info"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? 'Adding Merchant...' : 'Add Merchant'}
                                                </MDButton>
                                            </Grid>
                                        </Grid>
                                    </MDBox>

                                </Form>
                            )}
                        </Formik>
                    </MDBox>
                </Card>
            </MDBox>
            {/* Response Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Merchant Data</DialogTitle>
                <DialogContent>

                    <div>
                        <div>Dashboard: payhub.link</div>
                        <div>Email id: {responseData?.emailId}</div>
                        <div>Password: {responseData?.password}</div>
                        <div>Apikey: {responseData?.apiKey}</div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCopyToClipboard} color="primary">
                        Copy to Clipboard
                    </Button>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {/* <Footer /> */}
        </DashboardLayout>
    );
}

export default addMerchant;
