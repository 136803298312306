import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React from 'react'
import MDTypography from 'components/MDTypography'
import { Grid } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import Paper from '@mui/material/Paper';
import MDButton from 'components/MDButton'
import Container from '@mui/material/Container';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import MDInput from 'components/MDInput'
import MDBox from 'components/MDBox'
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useConfig } from "../../../../config"
import { useMaterialUIController } from 'context'
import { useSandbox } from 'ZustandState/useSanbox'
function UpdateLimit() {
    const { sandbox } = useSandbox()
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    const { state } = useLocation()
    const { apiUrl } = useConfig()
    const navigate = useNavigate();
    const adminEmail = localStorage.getItem('admin_email')
    const adminApiKey = localStorage.getItem('admin_apiKey')
    const initialValues = {
        emailId: adminEmail || '',
        apiKey: adminApiKey || '',
        email_id: state.email || '',
        payinLimit: state.payinLimit || 0,
        payoutLimit: state.payoutLimit || 0,
    }
    const validationSchema = Yup.object({
        payinLimit: Yup.number(),
        payoutLimit: Yup.number(),
    });
    const handleCancelClick = () => {
        // Go back to the previous page
        navigate(-1);
    };
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            if (sandbox) {
                toast.error("This feature is not permitted in sandbox!", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            if (values.emailId === '' || values.apiKey === '') {
                toast.error("Invalid credential! Please Sign in again..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            const response = await fetch(`${apiUrl}/admin/setDailyLimit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }

            setSubmitting(false);
            toast.success(res.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });
            navigate(-1)
        } catch (error) {
            // Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });

            // Reset submitting state
            setSubmitting(false);
        }
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, backgroundColor: darkMode ? '#344767' : 'white' }}>
                    <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                        <MDTypography variant="h6" gutterBottom>
                            Update Limit
                        </MDTypography>
                        <MDTypography variant="h6" textTransform="uppercase" gutterBottom>
                            {state.business}
                        </MDTypography>
                    </MDBox>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Grid item lg={12} xl={12} sm={12} xs={12}>
                                    <MDBox mb={2}>
                                        <Field type="number" label="Payin Limit" as={MDInput} fullWidth name="payinLimit" />
                                        <MDTypography color="error" variant="caption" >
                                            <ErrorMessage name="payinLimit" component="div" />
                                        </MDTypography>
                                    </MDBox>
                                </Grid>
                                <Grid item lg={12} xl={12} sm={12} xs={12}>
                                    <MDBox mb={2}>
                                        <Field type="number" label="Payout Limit" as={MDInput} fullWidth name="payoutLimit" />
                                        <MDTypography color="error" variant="caption" >
                                            <ErrorMessage name="payoutLimit" component="div" />
                                        </MDTypography>
                                    </MDBox>
                                </Grid>
                                <MDBox mt={4} mb={1} justifyContent="right">
                                    <Grid container justifyContent="right">
                                        <Grid item display={'flex'}>
                                            <MDBox>
                                                <MDButton
                                                    variant="gradient"
                                                    color="info"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? 'Saving...' : 'Save'}
                                                </MDButton>
                                            </MDBox>
                                            <MDBox ml={2}>
                                                <MDButton
                                                    variant="gradient"
                                                    color="error"
                                                    onClick={handleCancelClick}
                                                >
                                                    Cancel
                                                </MDButton>
                                            </MDBox>
                                        </Grid>
                                    </Grid>
                                </MDBox>

                            </Form>
                        )}
                    </Formik>
                </Paper>
            </Container>
        </DashboardLayout>
    )
}

export default UpdateLimit