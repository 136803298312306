import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'
import SettlementOverview from 'layouts/merchantSettlements/settlementsOverview'
import { useMaterialUIController } from 'context'
import { useConfig } from "../../config"
import Loader from 'util/Loader'
import { useSandbox } from 'ZustandState/useSanbox'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
function merchantSettlements() {
    const [controller] = useMaterialUIController()
    const navigate = useNavigate()
    const { darkMode } = controller
    const { apiUrl } = useConfig()
    const sandbox = useSandbox((state) => state.sandbox)
    const adminEmail = localStorage.getItem('admin_email');
    const adminApiKey = localStorage.getItem('admin_apiKey');
    const [merchantList, setMerchantList] = useState()
    const [loading, setLoading] = useState(true)
    const getMerchantList = async () => {
        try {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
            }
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getallmerchantlist`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
            const res = await response.json()
            if (res.responseCode !== 200) {
                // Show an error message
                toast?.error(res?.responseMessage || "An error occurred", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 2 seconds
                    hideProgressBar: false,
                });

                // Navigate to the login page
                navigate("/authentication/logout");
                return;
            }
            setMerchantList(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    useEffect(() => {
        setLoading(true)
        getMerchantList().then(() => {
            setLoading(false)
        })
    }, [sandbox])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={3}>
                <MDBox mt={1}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <MDTypography variant="h6" color={darkMode ? "white" : "black"}>Merchant Settlements</MDTypography>
                            {
                                !loading && (
                                    <MDBox
                                        mt={2}
                                    >

                                        <SettlementOverview list={merchantList} />
                                    </MDBox>
                                )
                            }
                            {
                                loading && (
                                    <Loader />
                                )
                            }
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
        </DashboardLayout>
    )
}

export default merchantSettlements