/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
export default function data(gatewayDetails, func) {
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    return {
        columns: [
            { Header: "Gateway", accessor: "gateway", width: "5%", align: "left" },
            { Header: "Today's Volume", accessor: "todaysVolume", width: "10%", align: "right" },
            { Header: "Successful Tx", accessor: "successfulTransaction", width: "10%", align: "right" },
            // { Header: "Today's Fees", accessor: "todaysFees", width: "10%", align: "right" },
            { Header: "Yesterday's Volume", accessor: "yesterdaysVolume", width: "10%", align: "right" },
            { Header: "Yesterday's Tx", accessor: "yesterdaysTransaction", width: "10%", align: "right" },
            // { Header: "Yesterday's Fees", accessor: "yesterdaysFees", align: "right", width: "10%", },
            { Header: "Today's Balance", accessor: "balance", align: "right", width: "10%", },
        ],

        rows: gatewayDetails && gatewayDetails.map((row, index) => ({
            gateway: (
                <MDTypography variant="caption" textTransform="uppercase" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {func(row?.gatewayName)}
                </MDTypography>
            ),
            todaysVolume: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {Number(row?.last24hr).toFixed(0)}
                </MDTypography>
            ),
            successfulTransaction: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {Number(row?.last24hrSuccess)}
                </MDTypography>
            ),
            // todaysFees: (
            //     <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
            //         {Number(row?.feeCollected24hr).toFixed(0)}
            //     </MDTypography>
            // ),
            yesterdaysVolume: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.yesterday.toFixed(0)}
                </MDTypography>
            ),
            yesterdaysTransaction: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.yesterdayTransactions}
                </MDTypography>
            ),
            // yesterdaysFees: (
            //     <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
            //         {row?.yesterdayFee.toFixed(0)}
            //     </MDTypography>
            // ),
            balance: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.todaysBalance.toFixed(0)}
                </MDTypography>
            ),
        }))
    }
}
