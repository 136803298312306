
import PropTypes from 'prop-types';
import DataTable from './DataTable';
import dataFormat from "layouts/merchantSettlements/settlementsOverview/data";
function SettlementsOverview(props) {
    const { list } = props
    const { columns, rows } = dataFormat(list);
    return (
        <>
            {columns && rows && columns.length > 0 && rows.length > 0 && (
                <DataTable
                    table={{ columns, rows }}
                    showTotalEntries={false}
                    isSorted={false}
                    noEndBorder
                    entriesPerPage={false}
                />
            )}
        </>

    );
}

SettlementsOverview.propTypes = {
    list: PropTypes.array
};

export default SettlementsOverview;
