/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
// import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
// import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import Transactions from "layouts/dashboard/components/Transactions"
// // Dashboard components
// import Projects from "layouts/dashboard/components/Projects";
// import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { useUser } from 'ZustandState/useUser';
import { useEffect, useState } from "react";
import { useConfig } from "../../config";
import { useSandbox } from "ZustandState/useSanbox";
import Loader from "util/Loader";
function Dashboard() {
  const { apiUrl } = useConfig()
  const sandbox = useSandbox((state) => state.sandbox)
  const adminEmail = localStorage.getItem('admin_email');
  const adminApiKey = localStorage.getItem('admin_apiKey');
  // const { sales, tasks } = reportsLineChartData;
  const userEmail = localStorage.getItem('admin_email')
  const setUser = useUser((state) => state.setUser)
  const [allDataLoading, setAllDataLoading] = useState(true)
  const [cardsData, setCardsData] = useState({
    todayVolume: 0,
    successfulTransaction: 0,
    successRate: 0,
    yesterdayVolume: 0,
    wallet: 0
  })
  const [isLoading, setIsLoading] = useState(true);
  async function setProfile(body) {
    try {

      const response = await fetch(`${apiUrl}/admin/getprofile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      });
      const res = await response.json();
      if (res.responseCode !== 200) {
        return;
      } const userBody = {
        emailId: res.responseData.emailId,
        apiKey: res.responseData.apiKey
      }
      if (res.responseData) {
        setUser(userBody)
      }
      return res;
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    setAllDataLoading(true)
    const userBody = {
      emailId: userEmail,
      apiKey:adminApiKey
    }
    setProfile(userBody).then((res) => {
      let adminBody = {
        emailId: res ? res?.responseData?.emailId : '',
        apiKey: res ? res?.responseData?.apiKey : ''
      }
      fetchData(adminBody)

    })
  }, [sandbox])

  async function getAdminBalance(body) {
    try {
      if (body.emailId === '' || body.apiKey === '') {
        return;
      }
      const response = await fetch(`${apiUrl}/admin/getadminbalance`, {
        method: 'POST',
        headers: {
          //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      })

      if (!response) console.log("Something went wrong")
      const res = await response.json()
      return res
    } catch (err) {
      console.log("Error Fetching Merchants: ", err)
    }
  }
  async function getVolumes(body) {
    try {
      if (body.emailId === '' || body.apiKey === '') {
        return;
      }
      const response = await fetch(`${apiUrl}/admin/getalltxdetails`, {
        method: 'POST',
        headers: {
          //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      })

      if (!response) console.log("Something went wrong")
      const res = await response.json()
      const data = res?.responseData
      return data
    } catch (err) {
      console.log("Error Fetching Merchants: ", err)
    }
  }
  const fetchData = async (body) => {
    try {
      const [walletData, volumesData] = await Promise.all([
        getAdminBalance(body),
        getVolumes(body),
      ]);

      // Once all data is fetched, update state and mark loading as false
      if (walletData && volumesData) {
        setCardsData({
          todayVolume: volumesData.todayObject.volume,
          successfulTransaction: volumesData.last24hrSuccess,
          successRate: volumesData.last24hrSuccessRate,
          yesterdayVolume: volumesData.yesterdayObject.volume,
          wallet: walletData.responseData
        });
        setAllDataLoading(false)
        setIsLoading(false);
      }

      //setAllDataLoading(false)
    } catch (err) {
      console.log("Error Fetching Data: ", err);
      setIsLoading(false); // Handle errors and stop loading
    }
  };

  const recieveStats = (data) => {
    if (data === "all") {
      let adminBody = {
        emailId: adminEmail || '',
        apiKey: adminApiKey || ''
      }

      fetchData(adminBody)
    } else {
      setCardsData({
        todayVolume: data?.last24hr,
        successfulTransaction: data?.successfulTransactions,
        successRate: data?.successRate,
        yesterdayVolume: data?.yesterday,
        wallet: data?.balance,
      })
    }

  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {
        allDataLoading ? (<Loader />) : (
          <MDBox py={3}>
            {
              !isLoading && (
                <Grid container spacing={3}>
                  <Grid item xs={12} md={2.4} lg={2.4}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        color="7FDF9A"
                        title="Today's Volume"
                        count={Number(cardsData?.todayVolume).toFixed(0)}
                      // percentage={{
                      //   color: "success",
                      //   amount: "+55%",
                      //   label: "than lask week",
                      // }}
                      />
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} md={2.4} lg={2.4}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        // icon="leaderboard"
                        title="Successful Transactions"
                        color="D4BEF0"
                        count={cardsData.successfulTransaction || 0}
                      // percentage={{
                      //   color: "success",
                      //   amount: "+0%",
                      //   label: "than last month",
                      // }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={2.4} lg={2.4}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        color="BBF2EA"
                        // icon="store"
                        title="Success Rate"
                        count={Math.round(cardsData?.successRate) + '%'}
                      // percentage={{
                      //   color: "success",
                      //   amount: "+0%",
                      //   label: "than yesterday",
                      // }}
                      />
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} md={2.4} lg={2.4}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        color="FFFED2"
                        //icon="equalizer"
                        title="Yesterday's Volume"
                        count={`${Number(cardsData?.yesterdayVolume).toFixed(0)}`}
                      // percentage={{
                      //   color: "success",
                      //   amount: "",
                      //   // label: "Just updated",
                      // }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={2.4} lg={2.4}>
                    <MDBox mb={1.5}>
                      <ComplexStatisticsCard
                        color="C9DEDE"
                        //icon="wallet"
                        title="Wallet"
                        count={cardsData?.wallet.toFixed(0)}
                      // percentage={{
                      //   color: "success",
                      //   amount: "+0%",
                      //   label: "than lask week",
                      // }}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
              )
            }

            <Grid container spacing={3} mt={4.5}>
              {/* <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="receipt_long"
                title="Merchant sucessfull transactions"
                count={successfulMerchantTransaction}
              // percentage={{
              //   color: "success",
              //   amount: "+1%",
              //   label: "than yesterday",
              // }}
              />
            </MDBox>
          </Grid> */}
              {/* <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="wallet"
                title="Balance"
                count={adminBalance}
              // percentage={{
              //   color: "success",
              //   amount: "+55%",
              //   label: "than lask week",
              // }}
              />
            </MDBox>
          </Grid> */}

            </Grid>
            {/* <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}
            <MDBox>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <Transactions recieveStatsFunc={recieveStats} />
                </Grid>
                {/* <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid> */}
              </Grid>
            </MDBox>
          </MDBox>
        )
      }

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Dashboard;
