import { Grid } from '@mui/material'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import MDBox from 'components/MDBox'
import { useEffect, useState } from 'react'
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard'
import DefaultProjectCard from 'examples/Cards/ProjectCards/DefaultProjectCard'
import SettlementLogs from "layouts/merchantSettlements/settlementLogs"
import MDTypography from 'components/MDTypography'
import { useMaterialUIController } from 'context'
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import { useConfig } from "../../../config"
import formatDateAndTime from "util/formatDateAndTime";
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import BeatLoader from "react-spinners/BeatLoader";
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import logo from "assets/images/payhub.png"
import { capitalizeFirstLetter } from 'util/formatDateAndTime'
import { useNavigate } from 'react-router-dom'
import Loader from 'util/Loader'
import { useSandbox } from 'ZustandState/useSanbox'
// import { MenuItem } from '@mui/material'
function settleMerchant() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const { apiUrl } = useConfig()
    const sandbox = useSandbox((state) => state.sandbox)
    const adminEmail = localStorage.getItem('admin_email');
    const adminApiKey = localStorage.getItem('admin_apiKey');
    const limit = 10
    const [page, setPage] = useState(0)
    const [csvCompleteData, setCsvCompleteData] = useState([])
    const [loading, setLoading] = useState(false)
    const [allDataLoading, setAllDataLoading] = useState(true)
    const validationSchema = Yup.object({
        amount: Yup.number().required('Gross Volume is required'),
        feesPercentage: Yup.number().min(0).max(100).required('Fees Percentage is required'),
        bankFees: Yup.number().min(0).max(100),
        usdtRate: Yup.number(),
        refund: Yup.number().required('Refund field is required'),
        chargeback: Yup.number().required('Chargeback field is required'),
        rolling_reserve: Yup.number().required('Rolling reserve field is required'),
        misc: Yup.number().required('Misc field is required'),
        gst: Yup.number().min(0).max(100).required('Gst field is required'),
        ref_no: Yup.string(),
    });
    const validationSchemaDateRange = Yup.object().shape({
        start_date: Yup.date()
            .required('Start date is required')
            .typeError('Invalid date'),
        end_date: Yup.date()
            .required('End date is required')
            .typeError('Invalid date')
            .min(
                Yup.ref('startDate'),
                'End date cannot be earlier than start date'
            ),
    });
    const [settlementLogs, setSettlementLogs] = useState([])
    const [controller] = useMaterialUIController()
    const {
        darkMode
    } = controller
    const [data, setData] = useState()
    const [totalData, setTotalData] = useState()
    const initialValues = {
        emailId: adminEmail || '',
        apiKey: adminApiKey || '',
        email_Id: state || '',
        amount: 0,
        feesPercentage: 0,
        bankFees: 0,
        usdtRate: 0,
        refund: 0,
        chargeback: 0,
        rolling_reserve: 0,
        misc: 0,
        gst: 0,
        ref_no: '',
        // notes: ''
    }
    const initialDateRangeValues = {
        emailId: adminEmail || '',
        apiKey: adminApiKey || '',
        email_Id: state || '',
        start_date: '',
        end_date: ''
    }
    const [netVolume, setNetVolume] = useState(0)
    const [netFees, setNetFees] = useState(0)
    const [netBankFees, setNetBankFees] = useState(0)
    const [usdtNet, setUsdtNet] = useState(0)
    const [netGst, setNetGst] = useState(0)
    const [openDialog, setOpenDialog] = useState(false);
    const handleCloseDialog = () => {
        setNetFees(0)
        setNetBankFees(0)
        setNetGst(0)
        setNetVolume(0)
        setUsdtNet(0)
        setOpenDialog(false);

    };
    // const downloadPdfInvoice = async (log) => {
    //     const pdfDoc = await PDFDocument.create();
    //     // Set page size to A4
    //     const page = pdfDoc.addPage([595, 842]); // 595 x 842 are the dimensions for A4 in points
    //     // Add left border Line
    //     page.drawLine({ start: { x: 8, y: 830 }, end: { x: 8, y: 12 }, thickness: 1, color: rgb(0, 0, 0) });
    //     // Add right border Line
    //     page.drawLine({ start: { x: 587, y: 830 }, end: { x: 587, y: 12 }, thickness: 1, color: rgb(0, 0, 0) });
    //     // Add upper border Line
    //     page.drawLine({ start: { x: 8, y: 830 }, end: { x: 587, y: 830 }, thickness: 1, color: rgb(0, 0, 0) });
    //     // Add bottom border Line
    //     page.drawLine({ start: { x: 8, y: 12 }, end: { x: 587, y: 12 }, thickness: 1, color: rgb(0, 0, 0) });

    //     // Add Company Logo
    //     const logoUrl = logo; // Replace with the actual path or URL to your logo
    //     const logoImageBytes = await fetch(logoUrl).then(res => res.arrayBuffer());
    //     const logoImage = await pdfDoc.embedPng(logoImageBytes);
    //     page.drawImage(logoImage, { x: 380, y: 770, width: 200, height: 40 });

    //     // Add Merchant Information
    //     const merchantInfo = [
    //         { title: 'Merchant Name', value: capitalizeFirstLetter(data?.business_name) },
    //         { title: 'Settlement Date', value: log.transaction_date.split('T')[0] || '' },
    //         { title: 'Settlement No.', value: (log.txIndex + 1).toString() },
    //     ];

    //     merchantInfo.forEach(({ title, value }, index) => {
    //         page.drawText(`${title}:`, { x: 50, y: 700 - index * 30, size: 18, color: rgb(0, 0, 0) });
    //         page.drawText(value, { x: 350, y: 700 - index * 30, size: 18, color: rgb(0, 0, 0) });
    //     });

    //     // Add Settlement Report
    //     page.drawRectangle({ x: 50, y: 580, width: 500, height: 30, color: rgb(0, 0, 0) });
    //     page.drawText('Settlement Report', { x: 200, y: 587, fontSize: 11, color: rgb(1, 1, 1) });

    //     const feeCharged = log?.feeCharged;
    //     const feeChargedValue = feeCharged !== undefined ? feeCharged.toFixed(0).toString() : '';

    //     // Add Data Section
    //     const dataSection = [
    //         { title: 'Merchant Volume', value: (log?.amount && log.amount.toFixed(0) || '').toString() },
    //         { title: 'Gateway Fees', value: (log?.feePercentage && log?.feePercentage + '%' || '').toString() },
    //         { title: 'Net Gateway Fees', value: feeChargedValue },
    //         { title: 'Bank Fees', value: (log?.bankFees && log?.bankFees + '%' || '').toString() },
    //         { title: 'Net Bank Fees', value: (log?.netBankFees && log?.netBankFees?.toFixed(0) || '').toString() },
    //     ];

    //     dataSection.forEach(({ title, value }, index) => {
    //         page.drawText(title, { x: 50, y: 530 - index * 35, size: 15, color: rgb(0, 0, 0) });
    //         page.drawText(value, { x: 400, y: 530 - index * 35, size: 15, color: rgb(0, 0, 0) });
    //     });

    //     // Add Footer Line
    //     page.drawLine({ start: { x: 50, y: 180 }, end: { x: 550, y: 180 }, thickness: 1, color: rgb(0, 0, 0) });

    //     // Add Footer Data
    //     const footerData = [
    //         { title: 'Merchant Net', value: (log?.amountSettled && log?.amountSettled.toFixed(0) || '').toString() },
    //         { title: 'USDT Rate', value: (log?.usdtRate).toString() || '' },
    //         { title: 'USDT Net Settlement', value: (log?.usdt?.toFixed(0)).toString() },
    //     ];

    //     footerData.forEach(({ title, value }, index) => {
    //         page.drawText(title, { x: 50, y: 150 - index * 30, size: 15, color: rgb(0, 0, 0) });
    //         page.drawText(value, { x: 400, y: 150 - index * 30, size: 15, color: rgb(0, 0, 0) });
    //     });

    //     let filename = `invoice,${capitalizeFirstLetter(data?.business_name)} ${formatDateAndTime(log.transaction_date).formattedDate}.pdf`

    //     // Save the PDF
    //     const pdfBytes = await pdfDoc.save();

    //     // Trigger download
    //     const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    //     const link = document.createElement('a');
    //     link.href = window.URL.createObjectURL(blob);
    //     link.download = filename;
    //     link.click();
    // }
    const createPDF = async (log) => {
        const pdfDoc = await PDFDocument.create();

        // Add a new page with full A4 dimensions
        const page = pdfDoc.addPage([595, 842]);

        // Load fonts
        const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
        const regularFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

        // Embed logo image
        const logoUrl = logo; // Assuming `logo` is defined elsewhere with the actual URL or path to the logo
        const logoImageBytes = await fetch(logoUrl).then((res) => res.arrayBuffer());
        const logoImage = await pdfDoc.embedPng(logoImageBytes);

        // Draw the Payhub logo at the top
        page.drawImage(logoImage, {
            x: 50,
            y: 760,
            width: 140,
            height: 30,
        });

        // Header - Draw title below the logo
        page.drawText(`Settlement Report - ${log.transaction_date.split('T')[0]}`, {
            x: 50,
            y: 720,
            size: 32,
            font: boldFont,
            color: rgb(0, 0, 0),
        });

        // Draw the separator line below the title
        page.drawLine({
            start: { x: 50, y: 710 },
            end: { x: 545, y: 710 },
            thickness: 2,
            color: rgb(0.1, 0.2, 0.7),
        });

        // Greeting and introduction text
        page.drawText('Hello from Payhub!', { x: 50, y: 680, size: 12, font: boldFont, color: rgb(0, 0, 0) });
        page.drawText('Your daily settlement report is ready!', { x: 50, y: 665, size: 12, font: regularFont });
        page.drawText('The transaction details are enclosed for your reference.', { x: 50, y: 650, size: 12, font: regularFont });

        // Merchant name, Settlement Date, and Settlement No.
        page.drawText(`Merchant - ${capitalizeFirstLetter(data?.business_name)}`, {
            x: 50,
            y: 630,
            size: 12,
            font: regularFont,
            color: rgb(0, 0, 0),
        });
        page.drawText(`Settlement Date - ${log.transaction_date.split('T')[0]}`, {
            x: 50,
            y: 615,
            size: 12,
            font: regularFont,
        });
        page.drawText(`Settlement No. - ${(log.txIndex + 1)}`, {
            x: 50,
            y: 600,
            size: 12,
            font: regularFont,
        });

        // Define right boundaries for columns that need right alignment
        const rateColumnRightBoundary = 350; // Adjusted to shift left
        const amountColumnRightBoundary = 545;

        // Table header with updated column name and adjusted positions
        page.drawText('Particulars', { x: 50, y: 570, size: 12, font: boldFont, color: rgb(0, 0, 0) });
        page.drawText('Currency', { x: 220, y: 570, size: 12, font: boldFont, color: rgb(0, 0, 0) });
        page.drawText('Rate (%)', { x: rateColumnRightBoundary - 40, y: 570, size: 12, font: boldFont, color: rgb(0, 0, 0) });
        page.drawText('Type', { x: 400, y: 570, size: 12, font: boldFont, color: rgb(0, 0, 0) });
        page.drawText('Amount', { x: amountColumnRightBoundary - 50, y: 570, size: 12, font: boldFont, color: rgb(0, 0, 0), align: 'right' });

        // Draw line below header
        page.drawLine({
            start: { x: 50, y: 565 },
            end: { x: 545, y: 565 },
            thickness: 1,
            color: rgb(0.1, 0.2, 0.7),
        });

        // Table data with percentage and type (credit/debit) information
        const tableData = [
            { particulars: 'Total Amount', currency: 'INR', amount: log.amount.toFixed(2).toString(), rate: '', type: 'Credit' },
            { particulars: 'Platform Fee', currency: 'INR', amount: log?.feeCharged?.toFixed(2).toString() || '0.00', rate: log?.feePercentage?.toString() || '', type: 'Debit' },
            { particulars: 'Bank Fee', currency: 'INR', amount: log?.netBankFees?.toFixed(2).toString() || '0.00', rate: log?.bankFees?.toString() || '', type: 'Debit' },
            { particulars: 'GST', currency: 'INR', amount: log?.netGst?.toFixed(2).toString() || '0.00', rate: log?.gst?.toString() || '', type: 'Debit' },
            { particulars: 'Total Chargeback Amount', currency: 'INR', amount: log?.chargeback?.toFixed(2)?.toString() || '0.00', rate: '', type: 'Debit' },
            { particulars: 'MISC Debit', currency: 'INR', amount: log?.misc?.toFixed(2)?.toString() || '0.00', rate: '', type: 'Debit' },
            { particulars: 'Rolling Reserve', currency: 'INR', amount: log?.rolling_reserve?.toFixed(2)?.toString() || '0.00', rate: '', type: 'Debit' },
            { particulars: 'Total Refund Amount', currency: 'INR', amount: log?.refund?.toFixed(2).toString() || '0.00', rate: '', type: 'Credit' },
            { separator: true }, // Marker to indicate a line after this item
            { particulars: 'Net Amount', currency: 'INR', amount: log?.amountSettled?.toFixed(2).toString() || '0.00', rate: '', type: 'Credit' },
        ];


        // Draw table rows with consistent spacing, adjusted column positions, and right-aligned Rate and Amount columns
        let yPosition = 540;
        tableData.forEach((item) => {
            if (item.separator) {
                // Draw line to separate MISC Debit and Net Amount
                yPosition -= 10;
                page.drawLine({
                    start: { x: 50, y: yPosition },
                    end: { x: 545, y: yPosition },
                    thickness: 1,
                    color: rgb(0.1, 0.2, 0.7),
                });
                yPosition -= 20; // Move position down for the next row after the line
                return; // Skip rendering text for this separator row
            }

            // Draw text for each item with adjusted column positions
            page.drawText(item.particulars, { x: 50, y: yPosition, size: 12, font: regularFont, color: rgb(0, 0, 0) });
            page.drawText(item.currency, { x: 220, y: yPosition, size: 12, font: regularFont, color: rgb(0, 0, 0) });

            // Right-align Rate (%) column with adjusted position
            const rateTextWidth = regularFont.widthOfTextAtSize(item.rate, 12);
            page.drawText(item.rate, {
                x: rateColumnRightBoundary - rateTextWidth,
                y: yPosition,
                size: 12,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            page.drawText(item.type, { x: 400, y: yPosition, size: 12, font: regularFont, color: rgb(0, 0, 0) });

            // Right-align Amount column
            const amountTextWidth = regularFont.widthOfTextAtSize(item.amount, 12);
            page.drawText(item.amount, {
                x: amountColumnRightBoundary - amountTextWidth, // Right-align to boundary
                y: yPosition,
                size: 12,
                font: regularFont,
                color: rgb(0, 0, 0),
            });

            yPosition -= 20;
        });


        // USDT Conversion Summary Section
        yPosition -= 30;  // Add extra space between the main table and the USDT summary
        page.drawText('USDT Conversion Summary', { x: 50, y: yPosition, size: 14, font: boldFont, color: rgb(0, 0, 1) });
        yPosition -= 20;
        page.drawText(`USDT Rate: ${log?.usdtRate?.toString() || 'N/A'} INR`, { x: 50, y: yPosition, size: 12, font: regularFont, color: rgb(0.2, 0.2, 0.2) });
        yPosition -= 20;
        page.drawText(`Equivalent in USDT: ${log?.usdt?.toFixed(2) || 'N/A'}`, { x: 50, y: yPosition, size: 12, font: regularFont, color: rgb(0.2, 0.2, 0.2) });

        // Remarks Section
        yPosition -= 30; // Space between USDT Summary and Remarks section
        page.drawText('Remarks', { x: 50, y: yPosition, size: 14, font: boldFont, color: rgb(0, 0, 1) });
        yPosition -= 20;
        page.drawText(log?.ref_no || 'No remarks provided.', { x: 50, y: yPosition, size: 12, font: regularFont, color: rgb(0.2, 0.2, 0.2) });

        // Additional space to ensure the footer has enough separation
        yPosition -= 30;

        // Footer section with links and contact
        page.drawLine({
            start: { x: 50, y: yPosition + 10 }, // Add line above footer
            end: { x: 545, y: yPosition + 10 },
            thickness: 1,
            color: rgb(0.1, 0.2, 0.7),
        });

        yPosition -= 20; // Positioning footer content below the line
        page.drawText('Got questions? FAQ or get in touch with one of our experts here.', { x: 50, y: yPosition, size: 10, font: regularFont, color: rgb(0, 0, 1) });
        yPosition -= 15;
        page.drawText('Thank you!\nTeam Payhub', { x: 50, y: yPosition - 20, size: 10, font: regularFont, color: rgb(0, 0, 0) });



        // Save and download the PDF
        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `invoice,${capitalizeFirstLetter(data?.business_name)} ${formatDateAndTime(log.transaction_date).formattedDate}.pdf`;
        link.click();
    };
    const getMerchantData = async () => {
        try {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
                email_Id: state || ''
            }
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getmerchantdata`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setData(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    const getMerchantTotalData = async () => {
        try {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
                email_Id: state || ''
            }
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/gettotalvolumeandsettlements`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setTotalData(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    const getSettlementLogs = async () => {
        try {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
                email_Id: state || '',
                limit,
                skip: page * limit,
            }
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getmerchantsettlements`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setSettlementLogs(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    const getSettlementLogsForCsv = async (body) => {
        try {
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getmerchantsettlements`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            return res?.responseData
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }

    const handleSettle = async (values, { setSubmitting }) => {
        try {
            if (values.emailId === '' || values.apiKey === '') {
                toast.error("Invalid credential! Please Sign in again..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            values.netFees = netFees;
            values.netVolume = netVolume;
            values.netUSDT = usdtNet;
            values.netBankFees = netBankFees
            values.netGst = netGst
            const response = await fetch(`${apiUrl}/admin/settlemoney`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }

            setSubmitting(false);
            toast.success(res.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });
            page > 0 ? (setPage(0), getMerchantTotalData(), getMerchantData()) : (getSettlementLogs(), getMerchantTotalData(), getMerchantData())
            handleCloseDialog()
        } catch (error) {
            // Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });

            // Reset submitting state
            setSubmitting(false);
        }
    };

    const downloadSettlementLogs = async () => {
        setLoading(true)
        setCsvCompleteData([])
        let csvPage = 0
        let responseData = true
        do {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
                email_Id: state || '',
                limit: 500,
                skip: csvPage * 500,
            }

            await getSettlementLogsForCsv(body).then((res) => {
                if (res.length === 0) responseData = false;
                csvCompleteData.push(...res)
            })
            csvPage++
        } while (responseData)

        // Create the CSV content
        const csvContent = [
            ["Time", "Date", "Gross Volume", "Net Fees", "Bank Fees", "Net Gst", "Chargeback", "Rolling Reserve", "Misc", "Refund", "Net Volume", "USDT Rate", "USDT Net", "Ref"],
            ...csvCompleteData.map(({ transaction_date, amount, feeCharged, netBankFees, netGst, chargeback, rolling_reserve, misc, refund, amountSettled, usdtRate, usdt, ref_no }) => [
                formatDateAndTime(transaction_date).formattedTime || '',
                formatDateAndTime(transaction_date).formattedDate || '',
                amount,
                feeCharged?.toFixed(2),
                netBankFees?.toFixed(2),
                netGst?.toFixed(2),
                chargeback,
                rolling_reserve,
                misc,
                refund,
                amountSettled?.toFixed(2),
                usdtRate,
                usdt?.toFixed(2),
                ref_no || '',
                // notes || ''
            ]),
        ].map(row => row.join(',')).join('\n');

        // Create a Blob containing the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a download link
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'settlement.csv';

        // Programmatically click the link to trigger the download
        a.click();

        // Clean up
        URL.revokeObjectURL(url);
        setLoading(false)
    }

    const calculateNetVolume = (grossVolume, fee, usdtRates, bankfee, gstPercentage, chargeback, rolling, misc, refund) => {
        const fees = (fee / 100) * grossVolume; // Calculating fees based on percentage
        let net;
        net = grossVolume - fees; // Calculating net volume
        const bankfees = (bankfee / 100) * net; // Calculating fees based on percentage
        net = net - bankfees
        const gstfees = (gstPercentage / 100) * net
        net = net - gstfees
        net = net - chargeback
        net = Number(net) - Number(rolling)
        net = net - misc
        net = Number(net) + Number(refund)
        setNetBankFees(bankfees)
        setNetFees(fees)
        setNetVolume(net);
        setNetGst(gstfees)
        calculateNetUSDT(usdtRates, net)
    }
    const calculateNetUSDT = (rate, netVolume) => {
        const net = (netVolume !== undefined && netVolume !== '') ? parseFloat(netVolume) : 0;
        const usdtRate = (rate !== undefined && rate !== '') ? parseFloat(rate) : 0;

        if (usdtRate === 0) {
            // Handle division by zero case
            setUsdtNet(0);
        } else {
            const netUSDT = net / usdtRate; // Calculating net USDT based on rate
            setUsdtNet(netUSDT);
        }
    }

    const handleDateRangeSubmit = async (values, { setSubmitting }) => {
        try {
            if (values?.start_date === "" || values?.end_date === "" || new Date(values.end_date) < new Date(values.start_date)) {
                toast.error("Invalid Date Range", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 2 seconds
                    hideProgressBar: false,
                });
                return;
            }

            const response = await fetch(`${apiUrl}/admin/downloadSettlementCsvByDate`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            })

            const res = await response.json()
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            const a = document.createElement('a');
            a.href = res?.responseData;
            a.download = 'settlement.csv';
            a.click(); // Programmatically click the link to trigger the download
            URL.revokeObjectURL(res?.responseData);  // Clean up
        } catch (err) {
            console.log("Error Fetching DateRange Csv: ", err)
        }
    }


    useEffect(() => {
        setAllDataLoading(true)
        getMerchantData()
        getMerchantTotalData().then(() => {
            setAllDataLoading(false)
        })
    }, [state, sandbox])
    useEffect(() => {
        getSettlementLogs()
    }, [state, page, sandbox])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {
                allDataLoading ? (<Loader />) : (
                    <MDBox py={3}>
                        <MDBox mt={1}>
                            {data && totalData && <Grid display={'flex'} flexDirection={'row'} container spacing={3}>
                                <Grid xl={3}>
                                    <DefaultProjectCard
                                        business={data?.business_name}
                                        email={data?.emailId}
                                        name={`${data?.first_name} ${data?.last_name}`}
                                        btn={setOpenDialog}
                                        type="settle"
                                    />
                                </Grid>
                                <Grid xl={9}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={2.4} xl={2.4}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="7FDF9A"
                                                    title="Today's Volume"
                                                    count={Number(data?.last24hr).toFixed(0) || ''}
                                                />
                                            </MDBox>
                                        </Grid>

                                        <Grid item xs={12} lg={2.4} xl={2.4}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="FFFED2"
                                                    title="Yesterday's Volume"
                                                    count={`${Number(data?.yesterday).toFixed(0) || ''}`}
                                                />
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={2.4} xl={2.4}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="D4BEF0"
                                                    title="Total Volume"
                                                    count={`${Number(totalData?.totalVolume).toFixed(0) || ''}`}
                                                />
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={2.4} xl={2.4}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="BBF2EA"
                                                    title="Total Settlement"
                                                    count={`${Number(totalData?.totalSettlements).toFixed(0) || ''}`}
                                                />
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={2.4} xl={2.4}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="C9DEDE"
                                                    title="Wallet Balance"
                                                    count={`${Number(data.balance).toFixed(0) || 0}`}
                                                />
                                            </MDBox>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>}
                            {/* settlementLogs */}
                            <MDBox mt={5} mx={0.5} ml={3}>
                                <Grid display={'flex'} flexDirection={'row'} container spacing={3}>
                                    <Grid xl={12}>
                                        <MDBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" py={1.5}>
                                            {/* Left section: Title and Loader/Download icon */}
                                            <MDBox display="flex" flexDirection="row" alignItems="center">
                                                <MDTypography textTransform="uppercase" variant="h6" color={darkMode ? "white" : "black"}>
                                                    Settlement Logs
                                                </MDTypography>
                                                <MDBox pl={3}>
                                                    {loading ? (
                                                        <BeatLoader color="#36d7b7" cssOverride={{}} size={10} />
                                                    ) : (
                                                        <FileDownloadOutlinedIcon
                                                            onClick={() => downloadSettlementLogs()}
                                                            sx={{ cursor: "pointer", fontWeight: "bold", color: darkMode ? "white" : "black" }}
                                                            fontSize="medium"
                                                        />
                                                    )}
                                                </MDBox>
                                            </MDBox>

                                            {/* Right section: Date inputs and Download button */}
                                            <MDBox display="flex" flexDirection="row" alignItems="center">
                                                <Formik initialValues={initialDateRangeValues} onSubmit={handleDateRangeSubmit} >
                                                    {({ handleChange, values, isSubmitting }) => (
                                                        <Form>
                                                            <Grid container spacing={2} alignItems="center">
                                                                {/* Start Date Input */}
                                                                <Grid item xs={12} sm={6} md={4}>
                                                                    <Field
                                                                        as={MDInput}
                                                                        label="Start Date"
                                                                        size="large"
                                                                        fullWidth
                                                                        type="date"
                                                                        name="start_date"
                                                                        onChange={handleChange}
                                                                        value={values.start_date}
                                                                        variant="outlined"
                                                                        InputLabelProps={{ shrink: true }}
                                                                    />
                                                                </Grid>

                                                                {/* End Date Input */}
                                                                <Grid item xs={12} sm={6} md={4}>
                                                                    <Field
                                                                        as={MDInput}
                                                                        label="End Date"
                                                                        size="large"
                                                                        fullWidth
                                                                        type="date"
                                                                        name="end_date"
                                                                        onChange={handleChange}
                                                                        value={values.end_date}
                                                                        variant="outlined"
                                                                        InputLabelProps={{ shrink: true }}
                                                                    />
                                                                </Grid>


                                                                {/* Download Button */}
                                                                <Grid item xs={12} sm={12} md={4} display="flex" justifyContent={{ xs: "center", md: "flex-start" }}>
                                                                    <MDButton
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color={isSubmitting ? "secondary" : "info"} // Change color when submitting
                                                                        sx={{
                                                                            height: "48px",
                                                                            minWidth: "150px",
                                                                            opacity: isSubmitting ? 0.5 : 1, // Fade button when submitting
                                                                            cursor: isSubmitting ? "not-allowed" : "pointer", // Show disabled cursor
                                                                        }}
                                                                        disabled={isSubmitting} // Disable button
                                                                    >
                                                                        {isSubmitting ? "Downloading..." : "Download"} {/* Change text */}
                                                                    </MDButton>
                                                                </Grid>
                                                            </Grid>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </MDBox>

                                        </MDBox>


                                    </Grid>
                                    <SettlementLogs pages={page} pageSetter={setPage} logs={settlementLogs} downloadInvoice={createPDF} />

                                </Grid>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                )
            }

            <Dialog PaperProps={{
                style: {
                    minHeight: '35vh',
                    minWidth: '45vw',
                    background: darkMode ? "#344767" : "white",
                    boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', // Shadow effect
                    borderRadius: '10px', // Rounded corners
                    position: 'absolute',
                    left: '50%', // Adjust the left value to move the dialog to the right
                },
            }} open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>SETTLEMENT</DialogTitle>
                <DialogContent>
                    <MDBox px={1} py={1} display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                        <MDTypography px={2} color="text" fontWeight="medium" fontSize="medium" variant="caption" >
                            Net Volume: {netVolume?.toFixed(2)}
                        </MDTypography>
                        <MDTypography px={2} color="text" fontWeight="medium" fontSize="medium" variant="caption" >
                            Net Fees: {netFees?.toFixed(2)}
                        </MDTypography>
                        <MDTypography px={2} color="text" fontWeight="medium" fontSize="medium" variant="caption" >
                            Net Bank Fees: {netBankFees?.toFixed(2)}
                        </MDTypography>
                        <MDTypography px={2} color="text" fontWeight="medium" fontSize="medium" variant="caption" >
                            Net GST: {netGst?.toFixed(2)}
                        </MDTypography>
                        <MDTypography px={2} color="text" fontSize="large" fontWeight="medium" variant="caption" >
                            Usdt Net: {usdtNet?.toFixed(2)}
                        </MDTypography>
                    </MDBox>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSettle}
                    >
                        {({ isSubmitting, values, setFieldValue }) => (
                            <Form>
                                <MDBox mb={2}>
                                    <Field type="number" label="Gross Volume" as={MDInput} fullWidth name="amount"
                                        onFocus={(e) => e.target.value === "0" && setFieldValue('amount', '')}
                                        onBlur={(e) => e.target.value === "" && setFieldValue('amount', 0)}
                                        onChange={(e) => {
                                            setFieldValue('amount', e.target.value)
                                            calculateNetVolume(e.target.value, values.feesPercentage, values.usdtRate, values.bankFees, values.gst, values.chargeback, values.rolling_reserve, values.misc, values.refund)
                                        }}
                                    />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="amount" component="div" />
                                    </MDTypography>
                                </MDBox>
                                {/* platform fees and bank fees */}
                                <Grid container spacing={2} mb={2}>
                                    <Grid item xs={6}>
                                        <Field type="number" label="Platform Fees %" as={MDInput} fullWidth name="feesPercentage"
                                            onFocus={(e) => e.target.value === "0" && setFieldValue('feesPercentage', '')}
                                            onBlur={(e) => e.target.value === "" && setFieldValue('feesPercentage', 0)}
                                            onChange={(e) => {
                                                setFieldValue('feesPercentage', e.target.value)
                                                calculateNetVolume(values.amount, e.target.value, values.usdtRate, values.bankFees, values.gst, values.chargeback, values.rolling_reserve, values.misc, values.refund)
                                            }}
                                        />
                                        <MDTypography color="error" variant="caption">
                                            <ErrorMessage name="feesPercentage" component="div" />
                                        </MDTypography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Field type="number" label="Bank Fees %" as={MDInput} fullWidth name="bankFees"
                                            onFocus={(e) => e.target.value === "0" && setFieldValue('bankFees', '')}
                                            onBlur={(e) => e.target.value === "" && setFieldValue('bankFees', 0)}
                                            onChange={(e) => {
                                                setFieldValue('bankFees', e.target.value)
                                                calculateNetVolume(values.amount, values.feesPercentage, values.usdtRate, e.target.value, values.gst, values.chargeback, values.rolling_reserve, values.misc, values.refund)
                                            }}
                                        />
                                        <MDTypography color="error" variant="caption">
                                            <ErrorMessage name="bankFees" component="div" />
                                        </MDTypography>
                                    </Grid>
                                </Grid>

                                {/* gst % and chargeback */}
                                <Grid container spacing={2} mb={2}>
                                    <Grid item xs={6}>
                                        <Field type="number" label="Gst %" as={MDInput} fullWidth name="gst"
                                            onFocus={(e) => e.target.value === "0" && setFieldValue('gst', '')}
                                            onBlur={(e) => e.target.value === "" && setFieldValue('gst', 0)}
                                            onChange={(e) => {
                                                setFieldValue('gst', e.target.value)
                                                calculateNetVolume(values.amount, values.feesPercentage, values.usdtRate, values.bankFees, e.target.value, values.chargeback, values.rolling_reserve, values.misc, values.refund)
                                            }}
                                        />
                                        <MDTypography color="error" variant="caption">
                                            <ErrorMessage name="gst" component="div" />
                                        </MDTypography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field type="number" label="Chargeback amount" as={MDInput} fullWidth name="chargeback"
                                            onFocus={(e) => e.target.value === "0" && setFieldValue('chargeback', '')}
                                            onBlur={(e) => e.target.value === "" && setFieldValue('chargeback', 0)}
                                            onChange={(e) => {
                                                setFieldValue('chargeback', e.target.value)
                                                calculateNetVolume(values.amount, values.feesPercentage, values.usdtRate, values.bankFees, values.gst, e.target.value, values.rolling_reserve, values.misc, values.refund)
                                            }}
                                        />
                                        <MDTypography color="error" variant="caption">
                                            <ErrorMessage name="chargeback" component="div" />
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                                {/* rolling reserve and misc */}
                                <Grid container spacing={2} mb={2}>
                                    <Grid item xs={6}>
                                        <Field type="number" label="Rolling reserve" as={MDInput} fullWidth name="rolling_reserve"
                                            onFocus={(e) => e.target.value === "0" && setFieldValue('rolling_reserve', '')}
                                            onBlur={(e) => e.target.value === "" && setFieldValue('rolling_reserve', 0)}
                                            onChange={(e) => {
                                                setFieldValue('rolling_reserve', e.target.value)
                                                calculateNetVolume(values.amount, values.feesPercentage, values.usdtRate, values.bankFees, values.gst, values.chargeback, e.target.value, values.misc, values.refund)
                                            }}
                                        />
                                        <MDTypography color="error" variant="caption">
                                            <ErrorMessage name="rolling_reserve" component="div" />
                                        </MDTypography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Field type="number" label="Misc" as={MDInput} fullWidth name="misc"
                                            onFocus={(e) => e.target.value === "0" && setFieldValue('misc', '')}
                                            onBlur={(e) => e.target.value === "" && setFieldValue('misc', 0)}
                                            onChange={(e) => {
                                                setFieldValue('misc', e.target.value)
                                                calculateNetVolume(values.amount, values.feesPercentage, values.usdtRate, values.bankFees, values.gst, values.chargeback, values.rolling_reserve, e.target.value, values.refund)
                                            }}
                                        />
                                        <MDTypography color="error" variant="caption">
                                            <ErrorMessage name="misc" component="div" />
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                                {/* refund and usdt rate */}
                                <Grid container spacing={2} mb={2}>
                                    <Grid item xs={6}>
                                        <Field type="number" label="Refund amount" as={MDInput} fullWidth name="refund"
                                            onFocus={(e) => e.target.value === "0" && setFieldValue('refund', '')}
                                            onBlur={(e) => e.target.value === "" && setFieldValue('refund', 0)}
                                            onChange={(e) => {
                                                setFieldValue('refund', e.target.value)
                                                calculateNetVolume(values.amount, values.feesPercentage, values.usdtRate, values.bankFees, values.gst, values.chargeback, values.rolling_reserve, values.misc, e.target.value)
                                            }}
                                        />
                                        <MDTypography color="error" variant="caption">
                                            <ErrorMessage name="refund" component="div" />
                                        </MDTypography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Field type="number" label="USDT Rate" as={MDInput} fullWidth name="usdtRate"
                                            onFocus={(e) => e.target.value === "0" && setFieldValue('usdtRate', '')}
                                            onBlur={(e) => e.target.value === "" && setFieldValue('usdtRate', 0)}
                                            onChange={(e) => {
                                                setFieldValue('usdtRate', e.target.value)
                                                calculateNetUSDT(e.target.value, netVolume)
                                            }}
                                        />
                                        <MDTypography color="error" variant="caption" >
                                            <ErrorMessage name="usdtRate" component="div" />
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                                <MDBox mb={2}>
                                    <Field type="text" label="Ref" as={MDInput} fullWidth name="ref_no" />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="ref_no" component="div" />
                                    </MDTypography>
                                </MDBox>
                                {/* <MDBox mb={2}>
                                    <Field type="text" label="Notes" as={MDInput} fullWidth name="notes" />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="notes" component="div" />
                                    </MDTypography>
                                </MDBox> */}
                                <MDBox display={'flex'} flexDirection={'row'}>
                                    <MDBox>
                                        <MDButton
                                            type="submit" color="success" disabled={isSubmitting}
                                        >
                                            {isSubmitting ? 'Settling...' : 'Settle Merchant'}
                                        </MDButton>
                                    </MDBox>
                                    <MDBox ml={3}>
                                        <MDButton
                                            onClick={handleCloseDialog} color="error"
                                        >
                                            Cancel
                                        </MDButton>
                                    </MDBox>
                                </MDBox>
                            </Form>
                        )}

                    </Formik>
                </DialogContent>

            </Dialog>
        </DashboardLayout>
    )
}

export default settleMerchant