import { Grid } from '@mui/material'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import MDBox from 'components/MDBox'
import { useEffect, useState } from 'react'
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard'
import GatewayInfoCard from '../gatewayInfoCard'
import SettlementLogs from "layouts/gateway-settlement/settlementLogs"
import MDTypography from 'components/MDTypography'
import { useMaterialUIController } from 'context'
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import { useConfig } from "../../../config"
import formatDateAndTime from "util/formatDateAndTime";
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import BeatLoader from "react-spinners/BeatLoader";
import Loader from 'util/Loader'
// import { PDFDocument, rgb } from 'pdf-lib';
// import logo from "assets/images/payhub.png"
import { capitalizeFirstLetter } from 'util/formatDateAndTime'
import { useNavigate } from 'react-router-dom'
import { useSandbox } from 'ZustandState/useSanbox'
function settleGateway() {
    const navigate = useNavigate()
    const { state } = useLocation()
    const { apiUrl } = useConfig()
    const sandbox = useSandbox((state) => state.sandbox)
    const adminEmail = localStorage.getItem('admin_email');
    const adminApiKey = localStorage.getItem('admin_apiKey');
    const limit = 10
    const [page, setPage] = useState(0)
    const [csvCompleteData, setCsvCompleteData] = useState([])
    const [loading, setLoading] = useState(false)
    const [allDataLoading, setAllDataLoading] = useState(true)
    const validationSchema = Yup.object({
        amount: Yup.number().required('Amount is required'),
        gst: Yup.string(),
        ref: Yup.string(),
        notes: Yup.string()
    });
    const [settlementLogs, setSettlementLogs] = useState([])
    const [controller] = useMaterialUIController()
    const {
        darkMode
    } = controller
    const [data, setData] = useState()
    const initialValues = {
        emailId: adminEmail || '',
        apiKey: adminApiKey || '',
        gatewayName: state,
        amount: '',
        gst: '',
        ref: '',
        notes: ''
    }
    const [openDialog, setOpenDialog] = useState(false);
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


    // with this we can get cardsData
    const getGatewayCardsData = async () => {
        try {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
                gatewayName: state
            }
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getgatewayvolume`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setData(res?.responseData)
        } catch (err) {
            console.log("Error Fetching getGatewayCardsData: ", err)
        }
    }
    // with this we can settle gateway form
    const handleSettle = async (values, { setSubmitting }) => {
        try {
            if (values.emailId === '' || values.apiKey === '') {
                toast.error("Invalid credential! Please Sign in again..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            const response = await fetch(`${apiUrl}/admin/updategatewaysettlements`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }

            setSubmitting(false);
            toast.success(res.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });
            page > 0 ? (setPage(0), getGatewayCardsData()) : (getGatewaySettlementLogs(), getGatewayCardsData())
            handleCloseDialog()
        } catch (error) {
            // Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });

            // Reset submitting state
            setSubmitting(false);
        }
    }
    // with this logs of gateway is fetched when active gateway is not all
    const getGatewaySettlementLogs = async () => {
        try {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
                gatewayName: state || '',
                limit,
                skip: page * limit,
            }
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getgatewaysettlements`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setSettlementLogs(res?.responseData)
        } catch (err) {
            console.log("Error Fetching SettlementLogs: ", err)
        }
    }
    // with this logs of gateway is fetched when active gateway is not all for CSV
    const getGatewaySettlementLogsForCsv = async (body) => {
        try {
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getgatewaysettlements`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            return res?.responseData
        } catch (err) {
            console.log("Error Fetching SettlementLogs For CSV: ", err)
        }
    }
    const downloadSettlementLogs = async () => {
        setLoading(true)
        setCsvCompleteData([])
        let csvPage = 0
        let responseData = true
        do {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
                gatewayName: state || '',
                limit: 500,
                skip: csvPage * 500,
            }

            await getGatewaySettlementLogsForCsv(body).then((res) => {
                if (res.length === 0) responseData = false;
                csvCompleteData.push(...res)
            })
            csvPage++
        } while (responseData)

        // Create the CSV content
        const csvContent = [
            ["No", "Time", "Date", "Gateway", "Amount Gross", "GST", "Ref", "Notes"],
            ...csvCompleteData.map(({ index, transaction_date, gatewayName, amount, gst, ref, notes }) => [
                index + 1,
                formatDateAndTime(transaction_date).formattedTime || '',
                formatDateAndTime(transaction_date).formattedDate || '',
                capitalizeFirstLetter(gatewayName),
                amount.toFixed(0),
                gst || '',
                ref || '',
                notes || ''
            ]),
        ].map(row => row.join(',')).join('\n');

        // Create a Blob containing the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a download link
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'GatewaySettlement.csv';

        // Programmatically click the link to trigger the download
        a.click();

        // Clean up
        URL.revokeObjectURL(url);
        setLoading(false)
    }
    useEffect(() => {
        setAllDataLoading(true)
        getGatewayCardsData().then(() => {
            setAllDataLoading(false)
        })

    }, [state, sandbox])
    useEffect(() => {
        getGatewaySettlementLogs()
    }, [state, page, sandbox])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={3}>
                {
                    allDataLoading ? (<Loader />) : (
                        <MDBox mt={1}>
                            {data && <Grid display={'flex'} flexDirection={'row'} container spacing={3}>
                                <Grid xl={3}>
                                    <GatewayInfoCard
                                        gateway={capitalizeFirstLetter(state)}
                                        settleGateway={setOpenDialog}
                                    />
                                </Grid>
                                <Grid xl={9}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} lg={2.4} xl={2.4}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="7FDF9A"
                                                    title="Today's Volume"
                                                    count={data?.last24hr?.toFixed(0) || 0}
                                                />
                                            </MDBox>
                                        </Grid>

                                        <Grid item xs={12} lg={2.4} xl={2.4}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="FFFED2"
                                                    title="Yesterday's Volume"
                                                    count={`${data?.yesterday?.toFixed(0) || 0}`}
                                                />
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={2.4} xl={2.4}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="D4BEF0"
                                                    title="Total Settlement"
                                                    count={`${data?.settlements?.toFixed(0) || 0}`}
                                                />
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={2.4} xl={2.4}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="BBF2EA"
                                                    title="Today's Fees"
                                                    count={`${data?.feeCollected24hr?.toFixed(0) || 0}`}
                                                />
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} lg={2.4} xl={2.4}>
                                            <MDBox mb={1.5}>
                                                <ComplexStatisticsCard
                                                    color="C9DEDE"
                                                    title="Today's Balance"
                                                    count={`${data?.balance?.toFixed(0) || 0}`}
                                                />
                                            </MDBox>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>}
                            {/* settlementLogs */}
                            <MDBox mt={5} mx={0.5} ml={3}>
                                <Grid display={'flex'} flexDirection={'row'} container spacing={3}>
                                    <Grid xl={12}>
                                        <MDBox display={'flex'} flexDirection={'row'}>
                                            <MDTypography variant="h6" color={darkMode ? "white" : "black"}>Settlement Logs</MDTypography>
                                            <MDBox pl={3}>
                                                {
                                                    loading ? (<BeatLoader
                                                        color="#36d7b7"
                                                        cssOverride={{}}
                                                        size={10}
                                                    />) : (
                                                        <FileDownloadOutlinedIcon onClick={() => downloadSettlementLogs()} sx={{ cursor: "pointer", fontWeight: "bold" }} color={darkMode ? "white" : "black"} fontSize="medium" />
                                                    )
                                                }

                                            </MDBox>
                                        </MDBox>
                                    </Grid>
                                    <SettlementLogs pages={page} pageSetter={setPage} logs={settlementLogs} />
                                </Grid>
                            </MDBox>
                        </MDBox>
                    )
                }

            </MDBox>
            <Dialog PaperProps={{
                style: {
                    minHeight: '35vh',
                    minWidth: '45vw',
                    background: darkMode ? "#344767" : "white",
                    boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2)', // Shadow effect
                    borderRadius: '10px', // Rounded corners
                    position: 'absolute',
                    left: '50%', // Adjust the left value to move the dialog to the right
                },
            }} open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>GATEWAY SETTLEMENT</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSettle}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <MDBox mb={2}>
                                    <Field type="number" label="Amount Gross" as={MDInput} fullWidth name="amount" />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="amount" component="div" />
                                    </MDTypography>
                                </MDBox>
                                <MDBox mb={2}>
                                    <Field type="number" label="GST" as={MDInput} fullWidth name="gst" />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="gst" component="div" />
                                    </MDTypography>
                                </MDBox>
                                <MDBox mb={2}>
                                    <Field type="text" label="Ref" as={MDInput} fullWidth name="ref" />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="ref" component="div" />
                                    </MDTypography>
                                </MDBox>
                                <MDBox mb={2}>
                                    <Field type="text" label="Notes" as={MDInput} fullWidth name="notes" />
                                    <MDTypography color="error" variant="caption" >
                                        <ErrorMessage name="notes" component="div" />
                                    </MDTypography>
                                </MDBox>
                                <MDBox display={'flex'} flexDirection={'row'}>
                                    <MDBox>
                                        <MDButton
                                            type="submit" color="success" disabled={isSubmitting}
                                        >
                                            {isSubmitting ? 'Settling...' : 'Settle Gateway'}
                                        </MDButton>
                                    </MDBox>
                                    <MDBox ml={3}>
                                        <MDButton
                                            onClick={handleCloseDialog} color="error"
                                        >
                                            Cancel
                                        </MDButton>
                                    </MDBox>
                                </MDBox>
                            </Form>
                        )}

                    </Formik>
                </DialogContent>

            </Dialog>
        </DashboardLayout>
    )
}

export default settleGateway