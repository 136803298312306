/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Billing page components
import Merchant from "layouts/merchant/allMerchants/merchant";
import PropTypes from "prop-types";
function MerchantInformation({ allMerchants, getAllMerchantsfunc }) {
    return (
        <Card id="delete-account">
            <MDBox pt={3} px={2}>
                <MDTypography variant="h6" fontWeight="medium">
                    Merchants Information
                </MDTypography>
            </MDBox>
            <MDBox pt={1} pb={2} px={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    {
                        allMerchants && allMerchants.map((item, index) => (
                            <Merchant
                                key={index}
                                first_name={item?.first_name || ''}
                                last_name={item?.last_name || ''}
                                business={item.business_name || ''}
                                email={item?.emailId || ''}
                                balance={item?.balance.toFixed(0)}
                                payinLimit={item?.payinLimit?.toFixed(0)}
                                payoutLimit={item?.payoutLimit?.toFixed(0)}
                                premium={item.premium}
                                gateway={item.payinGateway || ''}
                                payoutGateway={item.payoutGateway || ''}
                                platformFee={item.platformFee || 0}
                                isBanned={item?.is_Banned}
                                isActive={item?.payoutsActive}
                                func={getAllMerchantsfunc}
                            />
                        ))
                    }
                </MDBox>
            </MDBox>
        </Card>
    );
}
MerchantInformation.propTypes = {
    allMerchants: PropTypes.array,
    getAllMerchantsfunc: PropTypes.func

};
export default MerchantInformation;
